import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import AddToCalendarButton from "../../../common/AddToCalendarButton/AddToCalendarButton";
import { useStyles } from "./EventBody.style";
import Location from "../../../common/Icons/Location";
import labels from "../../../config/labels";
import Link from "../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import { isValidTime } from "../../../utils/helper";
import withConfig from "../../../utils/withConfig";
import Map from "../../../common/Map/Map";
import { isEventAddressOnline } from "../../../services/events";
import ContentBodyWithContactInformation from "../../ContentBody/WithContactInformation/ContentBodyWithContactInformation";
import {
  buildMapMarkerCoordenates,
  getViewInMapsUrl,
} from "../../../services/map";

const EventBody = ({ event, isEventPreview }) => {
  const [markerPosition, setMarkerPosition] = useState();
  const classes = useStyles();

  useEffect(() => {
    const loadMarker = async () => {
      const coordinates = await buildMapMarkerCoordenates(event?.full_address);
      setMarkerPosition(coordinates);
    };
    if (!isEventAddressOnline(event?.address)) {
      loadMarker();
    }
  }, []);

  const shouldShowMap = (is_virtual_event) => {
    if (markerPosition && !is_virtual_event) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <ContentBodyWithContactInformation
      mediaContent={event.media_content}
      description={event.description}
      isContentPreview={isEventPreview}
      information={
        <>
          <AddToCalendarButton
            starts={event.starts.standard}
            ends={event.ends.standard}
            title={event.title}
            description={event.description}
            address={event.address}
            timezone={event.timezone}
            className={classes.add_to_calendar_button_event_page}
            isEventPreview={isEventPreview}
            textProps={{
              color: "white",
              endIconColor: "white",
              bold: true,
              level: screenWidthIsLowerThan(1080) ? "t1_text_1" : "t2_text_2",
            }}
          />

          {event.starts.humanized_date && (
            <div className={classes.date_container}>
              <Typography
                level={
                  screenWidthIsLowerThan(960) ? "h1_header_1" : "h3_sub_header"
                }
                color="tapintoOrange"
                bold
                className={classes.date}
              >
                {event.starts.humanized_date}
              </Typography>
            </div>
          )}

          {isValidTime(event.starts.humanized_time) && !event.all_day && (
            <div className={classes.text_container}>
              <Typography
                level={
                  screenWidthIsLowerThan(960)
                    ? "h4_sub_header_4"
                    : "paragraph_2"
                }
              >
                {isValidTime(event.starts.humanized_time) &&
                isValidTime(event.ends.humanized_time)
                  ? event.starts.humanized_time +
                    labels.EM_DASH +
                    event.ends.humanized_time
                  : event.starts.humanized_time}
              </Typography>
            </div>
          )}

          {event.all_day && (
            <div className={classes.text_container}>
              <Typography
                level={
                  screenWidthIsLowerThan(960)
                    ? "h4_sub_header_4"
                    : "paragraph_2"
                }
                bold
                className={classes.all_day_text}
              >
                {labels.ALL_DAY}
              </Typography>
            </div>
          )}

          {event.virtual_event && (
            <div className={classes.text_container}>
              <Typography
                level={
                  screenWidthIsLowerThan(960)
                    ? "h4_sub_header_4"
                    : "paragraph_2"
                }
                bold
                className={classes.all_day_text}
              >
                {labels.VIRTUAL_EVENT}
              </Typography>
            </div>
          )}

          {!event.virtual_event && (
            <div className={classes.venue_address_city_container}>
              {event.venue && (
                <Typography
                  className={classes.location_info}
                  level="paragraph_2"
                  color="black"
                >
                  {event.venue}
                </Typography>
              )}
              <Typography
                className={classes.location_info}
                level="paragraph_2"
                color="black"
              >
                {event.address}
              </Typography>
              <Typography
                className={classes.location_info}
                level="paragraph_2"
                color="black"
              >
                {event.city + ", " + event.state + " " + event.zip_code}
              </Typography>
            </div>
          )}

          {event.virtual_event ? (
            <Link
              color="gray"
              level="h4_sub_header_4"
              bold
              className={classes.location_online}
              url={`${event.virtual_event_url}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              children={
                <>
                  {labels.CLICK_HERE}
                  <Location fillColor="gray" />
                </>
              }
            />
          ) : (
            <>
              {event.address && (
                <Link
                  color="gray"
                  level="t2_text_2"
                  className={classes.view_in_google_maps_link}
                  url={getViewInMapsUrl(event.full_address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  children={
                    <>
                      {labels.VIEW_IN_GOOGLE_MAPS}
                      <Location
                        fillColor="gray"
                        className={classes.location_icon}
                      />
                    </>
                  }
                />
              )}
            </>
          )}
        </>
      }
      contactInformationData={{
        name: event.contact_information.name,
        phone: event.contact_information.phone_number,
        email: event.contact_information.email,
        website: event.contact_information.website,
        facebook: event.contact_information.facebook,
        twitter: event.contact_information.twitter,
        instagram: event.contact_information.instagram,
      }}
      map={
        <>
          {shouldShowMap(event.virtual_event) && (
            <>
              <div
                className={clsx(
                  classes.google_map_frames,
                  classes.google_map_top_frame
                )}
              />
              <Map
                googleMapURL={`${withConfig("GOOGLE_MAPS_API_URL")}${withConfig(
                  "MAPS_API_KEY_TAPINTO"
                )}`}
                containerElement={<div className={classes.google_map} />}
                mapElement={<div className={classes.google_map_element} />}
                loadingElement={<p>{labels.LOADING}</p>}
                markerPosition={markerPosition}
              />
              <div
                className={clsx(
                  classes.google_map_frames,
                  classes.google_map_bottom_frame
                )}
              >
                <Link
                  color="white"
                  level="t2_text_2"
                  className={classes.view_in_google_maps_frame_link}
                  url={getViewInMapsUrl(event.full_address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  children={
                    <>
                      {labels.VIEW_IN_GOOGLE_MAPS}
                      <Location className={classes.location_icon} />
                    </>
                  }
                />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

EventBody.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    timezone: PropTypes.string,
    starts: PropTypes.shape({
      humanized_date: PropTypes.string,
      humanized_time: PropTypes.string,
      standard: PropTypes.any,
    }),
    ends: PropTypes.shape({
      humanized_date: PropTypes.string,
      humanized_time: PropTypes.string,
      standard: PropTypes.any,
    }),
    address: PropTypes.string,
    categories: PropTypes.array,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    description: PropTypes.string,
    slug: PropTypes.string,
    contact_information: PropTypes.shape({
      name: PropTypes.string,
      phone_number: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      instagram: PropTypes.string,
    }),
  }),
  isEventPreview: PropTypes.bool,
};

export default EventBody;
