import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Typography from "../../../../common/Typography/Typography";
import { useStyles } from "./InformationBlock.style";
import Map from "../../../../common/Map/Map";
import withConfig from "../../../../utils/withConfig";
import labels from "../../../../config/labels";
import Button from "../../../../common/Button/Button";
import ContactButtons from "../ContactButtons/ContactButtons";
import { isValidUrl } from "../../../../utils/helper";
import Link from "../../../../common/Link/Link";
import { buildMapMarkerCoordenates } from "../../../../services/map";
import { INHERITED_CLASS_FOR_CKEDITOR } from "../../../../common/Theme/customCssNames";

const InformationBlock = ({
  title,
  content,
  customClasses,
  shouldShowContactRealtorButton,
  slug,
  isContentPreview,
}) => {
  const [markerPosition, setMarkerPosition] = useState();
  const [isExpandedOverview, setIsExpandedOverview] = useState(false);
  const classes = useStyles();

  const isBlock = (type) => {
    return title && title.toLowerCase().includes(type);
  };

  const isHtmlContent = () => {
    return (
      typeof content === "string" &&
      (content.includes("/>") || content.includes("</"))
    );
  };

  const isListContent = () => {
    return Array.isArray(content);
  };

  const isReactElementContent = () => {
    return React.isValidElement(content);
  };

  const Content = () => {
    if (isListContent()) {
      return isBlock("contact") ? (
        content.map((item, index) => {
          return (
            item.label &&
            item.value && (
              <Fragment key={index}>
                <Typography
                  level="t2_text_2"
                  className={classes.contact_info_content_label}
                  bold
                >
                  {item.label + ": "}
                </Typography>
                {isValidUrl(item.value) ? (
                  <Link
                    children={item.value}
                    url={item.value}
                    underlined
                    level="t2_text_2"
                    className={classes.contact_info_link}
                    target="_blank"
                  />
                ) : (
                  <Typography
                    level="t2_text_2"
                    className={classes.contact_info_content}
                  >
                    {item.value}
                  </Typography>
                )}
              </Fragment>
            )
          );
        })
      ) : (
        <div className={classes.html_or_list_content}>
          <ul>
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      );
    } else if (isHtmlContent()) {
      return (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={clsx(
              INHERITED_CLASS_FOR_CKEDITOR,
              classes.html_or_list_content,
              isBlock("overview") && [
                classes.overview_block_text_contracted,
                isExpandedOverview && classes.overview_block_text_expanded,
              ]
            )}
          />
          {isBlock("overview") && content.length > 380 && (
            <Button
              level="simple_button"
              color="primary"
              children={
                isExpandedOverview ? labels.READ_LESS : labels.READ_MORE
              }
              onClick={() => setIsExpandedOverview(!isExpandedOverview)}
              classes={{
                root: clsx(classes.button, classes.button_label),
              }}
            />
          )}
        </>
      );
    } else if (isReactElementContent()) {
      return content;
    } else {
      return <Typography level="t2_text_2">{content}</Typography>;
    }
  };

  const getBlockClasses = () => {
    return isBlock("map")
      ? clsx(classes.map_block, customClasses?.map_block)
      : clsx(classes.information_block, customClasses?.block);
  };

  useEffect(() => {
    const loadMarker = async () => {
      const coordinates = await buildMapMarkerCoordenates(content);
      setMarkerPosition(coordinates);
    };

    if (content && isBlock("map")) {
      loadMarker();
    }
  }, [content]);

  return (
    <div className={getBlockClasses()}>
      {title && (
        <Typography level="h3_sub_header" className={classes.title}>
          {title}
        </Typography>
      )}

      <Content />

      {isBlock("map") && markerPosition && (
        <Map
          googleMapURL={`${withConfig("GOOGLE_MAPS_API_URL")}${withConfig(
            "MAPS_API_KEY_TAPINTO"
          )}`}
          containerElement={<div className={classes.google_map} />}
          mapElement={<div className={classes.google_map_element} />}
          loadingElement={<p>{labels.LOADING}</p>}
          markerPosition={markerPosition}
        />
      )}

      {isBlock("contact") && (
        <div className={classes.contact_buttons_container}>
          {shouldShowContactRealtorButton ? (
            <ContactButtons slug={slug} disabled={isContentPreview} />
          ) : (
            <ContactButtons
              buttonToShow="sendToAFriend"
              slug={slug}
              disabled={isContentPreview}
            />
          )}
        </div>
      )}
    </div>
  );
};

InformationBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    PropTypes.node,
  ]),
  customClasses: PropTypes.shape({
    block: PropTypes.string,
    map_block: PropTypes.string,
  }),
  shouldShowContactRealtorButton: PropTypes.bool,
  slug: PropTypes.string,
  isContentPreview: PropTypes.bool,
};

InformationBlock.defaultPropTypes = {
  shouldShowContactRealtorButton: true,
};

export default InformationBlock;
