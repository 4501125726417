import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./ContentBodyWithContactInformation.style";
import Carousel from "../../../common/Carousel/Carousel";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import Item from "../../../common/Carousel/Item";
import ContactInformationTemplate from "../../ContactInformationTemplate/ContactInformationTemplate";
import { isMobile } from "react-device-detect";
import StickySocialNetworkShareButtons from "../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import { INHERITED_CLASS_FOR_CKEDITOR } from "../../../common/Theme/customCssNames";

const ContentBodyWithContactInformation = ({
  information,
  mediaContent,
  description,
  contactInformation,
  useContactInformationTemplate,
  contactInformationTemplateStyleProps,
  contactInformationData,
  contactInformationTemplatePosition,
  map,
  classes,
  isContentPreview,
  videoPlayerProps,
}) => {
  const [isCarouselPopUpOpen, setIsCarouselPopUpOpen] = useState(false);
  const customClasses = useStyles(isMobile);

  const CarouselWithPopUp = () => {
    const nonVideoItemForPrintView = mediaContent.find(
      (item) => item.isVideo === false
    );
    return (
      <>
        {mediaContent?.length > 0 && (
          <div
            className={clsx(
              customClasses.carousel_container,
              classes?.carousel?.container && classes?.carousel?.container
            )}
          >
            <Carousel
              items={mediaContent}
              onImageClick={() => setIsCarouselPopUpOpen(true)}
              videoPlayerProps={videoPlayerProps}
            />

            <PopUpDialog
              onClose={() => setIsCarouselPopUpOpen(false)}
              openState={isCarouselPopUpOpen}
              title={""}
              content={<Carousel items={mediaContent} />}
              className={clsx(
                customClasses.carousel_dialog,
                classes?.carousel?.dialog && classes?.carousel?.dialog
              )}
              contentClass={clsx(
                customClasses.carousel_dialog_content,
                classes?.carousel?.dialog_content &&
                  classes?.carousel?.dialog_content
              )}
              titleClass={clsx(
                customClasses.carousel_dialog_title,
                classes?.carousel?.dialog_title &&
                  classes?.carousel?.dialog_title
              )}
            />
          </div>
        )}

        {nonVideoItemForPrintView && (
          <div className={customClasses.print_img}>
            <Item
              contentUrl={nonVideoItemForPrintView.contentUrl}
              caption={nonVideoItemForPrintView.caption}
              credits={nonVideoItemForPrintView.credits}
              alt_text={nonVideoItemForPrintView.alt_text}
              isVideo={nonVideoItemForPrintView.isVideo}
            />
          </div>
        )}
      </>
    );
  };

  const Map = () => {
    return (
      <div
        className={clsx(
          customClasses.map_container,
          classes.map_container && classes.map_container
        )}
      >
        {map}
      </div>
    );
  };

  const InformationSection = () => {
    return (
      <>
        {information ? (
          <div
            className={clsx(
              customClasses.information_container,
              classes.information_container && classes.information_container
            )}
          >
            {information}
          </div>
        ) : (
          useContactInformationTemplate &&
          contactInformationData &&
          contactInformationTemplatePosition === "top" && (
            <div
              className={clsx(
                customClasses.information_container,
                classes.information_container && classes.information_container
              )}
            >
              <ContactInformationTemplate
                data={contactInformationData}
                styleProps={contactInformationTemplateStyleProps}
              />
            </div>
          )
        )}
      </>
    );
  };

  return (
    <div
      className={clsx(
        customClasses.body_container,
        classes?.body_container && classes?.body_container
      )}
    >
      {!isContentPreview && <StickySocialNetworkShareButtons />}
      {screenWidthIsLowerThan(960) ? (
        <>
          <CarouselWithPopUp />
          <InformationSection />
          {map && <Map />}
        </>
      ) : (
        <>
          <div
            className={clsx(
              customClasses.main_block_container,
              classes?.main_block_container && classes?.main_block_container
            )}
          >
            <InformationSection />
            <CarouselWithPopUp />
          </div>
        </>
      )}

      {description && (
        <div
          className={clsx(
            INHERITED_CLASS_FOR_CKEDITOR,
            customClasses.description,
            classes?.description && classes?.description
          )}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      <div
        className={clsx(
          customClasses.contact_information_container,
          classes.contact_information_container &&
            classes.contact_information_container
        )}
      >
        {useContactInformationTemplate &&
        contactInformationData &&
        contactInformationTemplatePosition === "bottom" ? (
          <ContactInformationTemplate
            data={contactInformationData}
            styleProps={contactInformationTemplateStyleProps}
          />
        ) : (
          contactInformation && contactInformation
        )}
      </div>

      {!screenWidthIsLowerThan(960) && map && <Map />}
    </div>
  );
};

ContentBodyWithContactInformation.propTypes = {
  information: PropTypes.node,
  mediaContent: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string,
      credits: PropTypes.string,
      caption: PropTypes.string,
      alt_text: PropTypes.string,
      isVideo: PropTypes.bool,
    })
  ),
  contactInformation: PropTypes.node,
  description: PropTypes.string,
  map: PropTypes.node,
  classes: PropTypes.shape({
    body_container: PropTypes.string,
    map_container: PropTypes.string,
    information_container: PropTypes.string,
    contact_information_container: PropTypes.string,
    carousel: PropTypes.shape({
      container: PropTypes.string,
      dialog: PropTypes.string,
      dialog_content: PropTypes.string,
      dialog_title: PropTypes.string,
    }),
  }),
  videoPlayerProps: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  useContactInformationTemplate: PropTypes.bool,
  contactInformationData: PropTypes.shape({
    published_at: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
  }),
  contactInformationTemplatePosition: PropTypes.oneOf(["top", "bottom"]),
  contactInformationTemplateStyleProps: PropTypes.shape({
    title: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
    links: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
    content: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
    publishedAt: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
  }),
};

ContentBodyWithContactInformation.defaultProps = {
  classes: {
    body_container: "",
    map_container: "",
    information_container: "",
    contact_information_container: "",
    carousel: {
      container: "",
      dialog: "",
      dialog_content: "",
      dialog_title: "",
    },
  },
  useContactInformationTemplate: true,
  contactInformationTemplatePosition: "bottom",
};

export default ContentBodyWithContactInformation;
